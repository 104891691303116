import React from "react";
import IconButton from "@mui/material/IconButton";
import { Send } from "lucide-react";

const Input = ({
  value,
  onChange,
  onSubmit,
  onKeyDown,
  placeholder,
  isLoading,
  buttonStyle,
  inputStyle,
  containerStyle,
}) => {
  return (
    <div style={{ ...containerStyle }}>
      <input
        className="searchInput"
        type="text"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        onKeyDown={onKeyDown}
        style={inputStyle}
      />
      <IconButton onClick={onSubmit} style={buttonStyle} disabled={isLoading}>
        <Send />
      </IconButton>
    </div>
  );
};

export default Input;
