import React from "react";
import "./Footer.css";
const Footer = () => {
  return (
    <div>
      <p className="footer-text">
        <span className="footer-subtext">
          Made by <img src="assets/footerIcon.png" alt="" width={20} /> Development
        </span>{" "}
        About Findus/H24
      </p>
    </div>
  );
};

export default Footer;
