export async function handleInsertBodyContent(bodyContent) {
  return new Promise((resolve, reject) => {
    Office.context.mailbox.item.body.setAsync(bodyContent, { coercionType: "html" }, (result) => {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        resolve(true);
      } else {
        reject(result.error.message || "Failed to set body");
      }
    });
  });
}
