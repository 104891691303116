import React, { createContext } from "react";
import { createRoot } from "react-dom/client";
import App from "./components/App";
import { FluentProvider, webLightTheme } from "@fluentui/react-components";
import { languages } from "./components/Shared/Tranlation";
/* global document, Office, module, require */

let TranslationContext = createContext(null);

// Check if running in the Office environment (i.e., in a real add-in, not in tests)
if (typeof Office !== "undefined" && typeof document !== "undefined") {
  const rootElement = document.getElementById("container");
  if (rootElement) {
    const root = createRoot(rootElement);

    Office.onReady(() => {
      let myLanguage = Office.context.displayLanguage.split("-")[0];
      const UIText = languages[myLanguage];

      TranslationContext = createContext(UIText);
      root.render(
        <FluentProvider theme={webLightTheme}>
          <App />
        </FluentProvider>
      );
    });

    if (module.hot) {
      module.hot.accept("./components/App", () => {
        const NextApp = require("./components/App").default;
        root.render(<NextApp />);
      });
    }
  }
}

export { TranslationContext }; // Export the TranslationContext
