export function SpeechToText(file) {
  return new Promise(async (resolve, reject) => {
    try {
      // Ensure the file parameter is valid
      if (!file) {
        throw new Error("No file provided");
      }

      // Create FormData and append the file
      const formData = new FormData();
      formData.append("audio", file, file.name || "audio.mp3"); // Use file.name or default to "audio.mp3"

      // Make the fetch request
      const response = await fetch("https://rotest.heimat24.de/apps/rosenheim-main/api/speech-to-text/", {
        method: "POST",
        body: formData,
      });

      // Check if the response is OK (status code 2xx)
      if (!response.ok) {
        const errorText = await response.text(); // Get the error text for more details
        throw new Error(`Upload failed: ${response.statusText}. Details: ${errorText}`);
      }

      // Resolve the promise with the parsed response data
      const result = await response.json();
      resolve(result);
    } catch (error) {
      // Reject the promise with the error
      console.error("Speech to Text Error:", error); // Log the error for debugging
      reject(error);
    }
  });
}
