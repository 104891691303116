import React, { useContext, useState } from "react";
import { TranslationContext } from "../../..";
import { useNavigate } from "react-router-dom";
import { fetchConversationMessages } from "../../Utilities/FetchMessage";
import "./Home.css";
import MenuBar from "../../Shared/MenuBar/MenuBar";
import Footer from "../../Shared/Footer/Footer";
import Button from "../../Shared/Button/Button";
import { marked } from "marked";
const Home = () => {
  const language = useContext(TranslationContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleGenerateDraft = () => {
    fetchData();
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const reply = await fetchConversationMessages();
      let parsedContent = marked.parse(reply.reply.answer).toString();

      navigate("/GeneratedDraft", { state: { replyContent: parsedContent } });
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="main">
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          paddingRight: "10px",
        }}
      >
        <MenuBar />
      </div>
      <div className="GenerateContainer">
        <img src="assets/Home.png" alt="" width={140} className="image" />

        <div>
          <div className="GenerateContent">
            <h1 className="heading">{language.welcome.title}</h1>
            <p className="subheading">{language.welcome.subTitle}</p>
            <div className="button-container">
              <Button
                isLoading={isLoading}
                onClick={fetchData}
                loadingText={language.GeneratedDraft.WorkingButton}
                buttonText={language.welcome.generateButton}
              />
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
